<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Aging Clocks
            </h3>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Model
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Author
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Feature Highlights
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Methods
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Original Paper
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Horvath Aging Clock
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Steve Horvath
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                The first epigenetic clock that predicts age based on methylation of 353 CpG sites from DNA. The Horvath clock is a multi-tissue clock 
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Elastic Net Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/24138928/">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> DNA methylation age of human tissues and cell types
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Hannum Aging Clock
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Hannum G, Guinney J, Zhao L, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                A quantitative model of aging using measurements at more than 450,000 CpG markers from the whole blood of 656 human individuals, aged 19 to 101.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Elastic Net Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://pubmed.ncbi.nlm.nih.gov/23177740/">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> Genome-wide methylation profiles reveal quantitative views of human aging rates
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                DNAm PhenoAge
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Levine ME, Lu AT, Quach A, et al
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                A biological age predictor based on DNA methylation at 513 CpG islands as well as biochemical markers of age-related disease, including albumin, creatinine, glucose, C-reactive protein, alkaline phosphatase, and several blood components.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
               Elastic Net Regression, Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5940111/">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> An epigenetic biomarker of aging for lifespan and healthspan
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                DNAm GrimAge
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Lu AT, Quach A, Wilson JG, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                A predictor of lifespan based on the seven DNAm surrogates and a DNAm-based estimator of smoking pack-years. DNAm GrimAge stands out among existing epigenetic clocks in terms of its predictive ability for time-to-death, time-to-coronary heart disease and time-to-cancer.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Elastic Net Regression, Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6366976/">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> DNA methylation GrimAge strongly predicts lifespan and healthspan
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                DunedinPACE
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Belsky, Daniel W., et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                DunedinPACE was developed by analyzing changes in 19 clinical biomarkers of organ-system integrity over two decades within individuals from the Dunedin Study. This analysis was used to model the pace of aging, which was then captured in a single-time-point DNA methylation blood test through elastic-net regression. The method focused on using longitudinal clinical data to inform a genetic biomarker that quantifies biological aging.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Elastic Net Regression, Mixed-effect model
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://elifesciences.org/articles/73420#sa2">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> DunedinPACE, a DNA methylation biomarker of the pace of aging
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                bAge
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Bernabeu, E., McCartney, D.L., Gadd, D.A. et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                The bAge method was developed using large-scale EWAS to identify CpG sites associated with chronological age and mortality. Advanced modeling, including non-linear associations and feature selection through elastic net regression, improved the accuracy of age predictions.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Elastic Net Regression, Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://genomemedicine.biomedcentral.com/articles/10.1186/s13073-023-01161-y">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> Refining epigenetic prediction of chronological and biological age
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                DNAmRS
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Zhang Y, Wilson R, Heiss J, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                DNAmRS is a metric developed through epigenome-wide association studies that uses a combination of selected CpG sites associated with all-cause mortality to calculate an individual's risk of death, independent of traditional epigenetic aging markers.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                LASSO Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://www.nature.com/articles/ncomms14617#Sec8">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> DNA methylation signatures in peripheral blood strongly predict all-cause mortality
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                MetaboHealth score
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Deelen J, Kettunen J, Fischer K, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                The MetaboHealth score is a predictive metric derived from 14 circulating biomarkers identified using a metabolomics platform, which demonstrates a high accuracy in predicting 5- and 10-year all-cause mortality in a large cohort, outperforming conventional risk factors.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://www.nature.com/articles/s41467-019-11311-9#Sec9">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> A metabolic profile of all-cause mortality risk identified in an observational study of 44,168 individuals. 
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                MetaboAge score
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Van Den Akker E B, Trompet S, Barkey Wolf J J H, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                MetaboAge is a metabolomics-based age predictor constructed from over 25,000 samples across various cohorts, providing an estimation of biological age that correlates with increased risks of cardiovascular disease, mortality, and decreased functionality in older individuals
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Linear Model
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://www.ahajournals.org/doi/full/10.1161/CIRCGEN.119.002610#d1e1002">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i> Metabolic Age Based on the BBMRI-NL 1H-NMR Metabolomics Repository as Biomarker of Age-related Disease
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                M-metabo-score
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Balasubramanian R, Paynter N P, Giulianini F, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                The M-metabo-score is a validated metabolite-based score that predicts all-cause mortality risk in postmenopausal women, incorporating 17 metabolites associated with both protective and detrimental effects on longevity.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://academic.oup.com/ije/article/49/1/289/5607292?login=false#201521233">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i>  Metabolomic profiles associated with all-cause mortality in the Women’s Health Initiative.
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Proteomic signature
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Tanaka T, Basisty N, Fantoni G, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                A proteomic signature is a collection of plasma proteins that change with age and can predict the risk of chronic diseases and mortality, serving as a potential biomarker for aging.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Penalized regression model
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://elifesciences.org/articles/61073">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i>  Plasma proteomic biomarker signature of age predicts health and life span.
                </a>
              </td>
            </tr>

            <tr>
              <td
                class="ml-3 font-bold border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Integrative biomarker
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Huan T, Nguyen S, Colicino E, et al.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-normal p-4" style="width: 20%;"
              >
                An integrative biomarker merges DNA methylation patterns with clinical data to enhance mortality risk prediction, showing particular promise in forecasting cancer-related deaths.
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                Elastic Net Regression, Cox Regression
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <a href="https://onlinelibrary.wiley.com/doi/full/10.1111/acel.13608">
                    <i class="fas fa-link mr-2 text-lightBlue-600"></i>   Integrative analysis of clinical and epigenetic biomarkers of mortality.
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  <script>
  
  import bootstrap from "@/assets/img/bootstrap.jpg";
  import angular from "@/assets/img/angular.jpg";
  import sketch from "@/assets/img/sketch.jpg";
  import react from "@/assets/img/react.jpg";
  import vue from "@/assets/img/react.jpg";
  
  import team1 from "@/assets/img/team-1-800x800.jpg";
  import team2 from "@/assets/img/team-2-800x800.jpg";
  import team3 from "@/assets/img/team-3-800x800.jpg";
  import team4 from "@/assets/img/team-4-470x470.png";
  
  export default {
    data() {
      return {
        bootstrap,
        angular,
        sketch,
        react,
        vue,
        team1,
        team2,
        team3,
        team4,
      };
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
  };
  </script>
  