<template>
  <div>
    <navbar />
    <main>
      <div class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
        <div class="absolute top-0 w-full h-full bg-center bg-cover" style="
            background-image: url('https://images.unsplash.com/photo-1628595351029-c2bf17511435?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=4032&q=80');
          ">
          <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl" style="font-size:50px; font-family: Sylfaen;">
                  Welcome to the DNAm Aging Clock Platform
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  Our platform offers validated aging clock models based on DNA methylation analysis to measure biological age, with options for data upload, analysis, and visualization
                </p>
                <button class="bg-white text-black font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-8" type="button">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-200 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div class="px-6 py-8 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-5 shadow-lg rounded-full bg-red-400">
                    <i class="fas fa-microscope fa-2x"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Science-Backed</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    The epigenetic aging clock is a scientific method of measuring biological age by analyzing changes 
                    in DNA methylation patterns. It has been validated that the clock can accurately predict age and also provide insights into various age-related diseases.
                  </p>
                  <!-- <button class="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                    Explore
                  </button> -->
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div class="px-6 py-8 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                    <i class="fas fa-laptop-code fa-2x"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Our Platform</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Our platform is equipped with state-of-the-art aging clock models that enable you to upload your own DNA methylation data and receive analysis reports. 
                    We also offer several options for comparing results across different clocks and visualizing the data.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center mt-6">
              <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-5 shadow-lg rounded-full bg-emerald-400">
                    <i class="fas fa-file fa-2x"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Sample Datasets</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Our platform provides sample methylation datasets for multiple tissue types and Illumina BeadChip platforms, allowing you to test your models and compare the results. 
                    Additionally, you have the option to download these datasets for further analysis.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fas fa-hourglass-half text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Epigenetic Aging Clock </h3>
              <p class="text-md font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Epigenetic clocks are mathematically derived age estimators that are based on combinations of methylation values that change with age at specific CpGs in the genome. 

              </p>
              <p class="font-bold text-blueGray-700 mt-6 mb-2">
                1st Generation Aging Clocks
              </p>
              <p class="text-md font-light leading-relaxed mt-0 mb-3 text-blueGray-600">
                The 1st generation aging clocks, including the Horvath Clock and Hannum Clock, are types of aging clocks that utilize DNA methylation patterns to predict age. 
                Although these clocks are renowned for their high accuracy in predicting chronological age, they do not provide in-depth biological information.
              </p>
              <p class="font-bold text-blueGray-700 mt-6 mb-2">
                2nd Generation Aging Clocks
              </p>
              <p class="text-md font-light leading-relaxed mt-0 mb-3 text-blueGray-600">
                The 2nd Generation Aging Clocks, such as PhenoAge and GrimAge, are types of aging clocks that combine clinical markers from the blood with DNA methylation patterns to predict biological age. 
                These advanced aging clocks provide more comprehensive biological insights and are superior in predicting mortality and lifespan.
              </p>
              <p class="font-bold text-blueGray-700 mt-6 mb-2">
                Aging Acceleration and Age-associated Disease
              </p>
              <p class="text-md font-light leading-relaxed mt-0 mb-3 text-blueGray-600">
                Aging acceleration refers to the increase in biological age relative to chronological age, which is a hallmark of aging and age-associated diseases. 
                Age-associated diseases, such as Alzheimer's disease, cancer, and cardiovascular disease, are strongly correlated with aging acceleration. 
                Epigenetic aging clocks can be utilized to identify individuals who are at a higher risk of developing age-associated diseases and to monitor disease progression.
              </p>
              <router-link to="/" class="font-bold text-blueGray-700 mt-8">
                Learn More →
              </router-link>
            </div>

            <!-- <div class="w-full md:w-4/12 px-2 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500">
                <img alt="..."
                  src="https://d2r55xnwy6nx47.cloudfront.net/uploads/2022/08/Aging_2560_Lede.jpg"
                  class="w-full align-middle rounded-t-lg" />
                <blockquote class="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px">
                    <polygon points="-30,95 583,95 583,65" class="text-emerald-500 fill-current"></polygon>
                  </svg>
                  <h4 class="text-lg font-bold text-white">
                    We are here to support your research </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Validate your ideas quickly with cutting-edge aging clock models.
                  </p>
                </blockquote>
              </div>
            </div> -->
            <div class="w-full md:w-4/12 px-2 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-blueGray-600 w-full mb-6 shadow-lg rounded-lg">
                <img alt="..."
                  src="https://d2r55xnwy6nx47.cloudfront.net/uploads/2022/08/Aging_2560_Lede.jpg"
                  class="w-full align-middle rounded-t-lg" />
                <blockquote class="relative p-8 mb-4">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px">
                    <polygon points="-30,95 583,95 583,65" class="text-blueGray-600 fill-current"></polygon>
                  </svg>
                  <h4 class="text-lg font-bold text-white">
                    We are here to support your research </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Validate your ideas quickly with cutting-edge aging clock models.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>

        <div style="width: 70%; margin: 0 auto; display: flex; justify-content: center; align-items: center; padding-bottom: 100px;">
          <InfoComponent />
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <img alt="..." class="max-w-full rounded-lg shadow-lg"
                src="https://www.labclinics.com/wp-content/uploads/2021/05/Metilacion-3.png" />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i class="fas fa-dna text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">DNA Methylation</h3>
                <p class="mt-4 text-md leading-relaxed text-blueGray-500">
                  DNA methylation is an epigenetic modification that involves the addition of methyl groups to specific DNA molecules, 
                  resulting in changes in gene expression without altering the DNA sequence itself. Methylation patterns can change over time, 
                  and these changes have been linked to aging and age-related diseases.
                </p>
                <p class="mt-4 mb-6 text-md leading-relaxed text-blueGray-500">
                  As we age, our DNA methylation patterns undergo changes, which can lead to alterations in gene expression and cellular function. 
                  These changes can contribute to age-related diseases such as cancer, heart disease, and neurodegeneration. By analyzing DNA 
                  methylation patterns, scientists can develop aging clocks that accurately predict biological age and provide insights into the 
                  aging process and age-related diseases.
                </p>
                <router-link to="/" class="font-bold text-blueGray-700">
                  Learn More →
                </router-link>
              </div>
            </div>
          </div>


          <!-- <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                DNA Methylation Aging Clock Platform </h3>
              <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Welcome to our DNA Methylation Aging Clock platform, where you can uncover your biological age based on
                your own DNA methylation data.

              </p>
              <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                Our project utilizes advanced aging clock models to provide you with accurate predictions of your
                biological age, helping you understand your body and overall health better.
              </p>
              <router-link to="/" class="font-bold text-blueGray-700 mt-8">
                Get Started!
              </router-link>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500">
                <img alt="..."
                  src="https://media.istockphoto.com/id/1297146235/photo/blue-chromosome-dna-and-gradually-glowing-flicker-light-matter-chemical-when-camera-moving.jpg?s=612x612&w=0&k=20&c=yjSdodXRBvtwzOYtQTqetnn3b4wWDNpF6keupxqxric="
                  class="w-full align-middle rounded" />
              </div>
            </div>
          </div> -->

        </div>
      </section>





      <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Cooperation Partners</h2>
              <p class="text-lg leading-relaxed m-4 text-blueGray-500">
                AgingClock Technology collaborates with renowned entities across government and scientific research, uniting in our mission to advance AgingClock consensus.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center">
            <!-- Image containers with smaller horizontal padding for reduced spacing -->
            <div class="px-2 mb-12 flex justify-center items-center w-full sm:w-1/2 md:w-4/12 lg:w-1/5">
              <img alt="HKSTP" :src="HKSTP" class="shadow-lg rounded-full h-auto align-middle border-none" style="width: 120px; object-fit: contain;"/>
            </div>
            <div class="px-2 mb-12 flex justify-center items-center w-full sm:w-1/2 md:w-4/12 lg:w-1/5">
              <img alt="innoHK" :src="innoHK" class="shadow-lg rounded-full h-auto align-middle border-none" style="width: 120px; object-fit: contain;"/>
            </div>
            <div class="px-2 mb-12 flex justify-center items-center w-full sm:w-1/2 md:w-4/12 lg:w-1/5">
              <img alt="HKQAI" :src="HKQAI" class="shadow-lg rounded-full h-auto align-middle border-none" style="width: 120px; object-fit: contain;"/>
            </div>
            <div class="px-2 mb-12 flex justify-center items-center w-full sm:w-1/2 md:w-4/12 lg:w-1/5">
              <img alt="HKU" :src="HKU" class="shadow-lg rounded-full h-auto align-middle border-none" style="width: 120px; object-fit: contain;"/>
            </div>
            <div class="px-2 mb-12 flex justify-center items-center w-full sm:w-1/2 md:w-4/12 lg:w-1/5">
              <img alt="CUHK" :src="CUHK" class="shadow-lg rounded-full h-auto align-middle border-none" style="width: 120px; object-fit: contain;"/>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="pb-20 relative block bg-blueGray-800">
        <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);">
          <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
            version="1.1" viewBox="0 0 2560 100" x="0" y="0">
            <polygon class="text-blueGray-800 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">
                Build something
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                Put the potentially record low maximum sea ice extent tihs year
                down to low ice. According to the National Oceanic and
                Atmospheric Administration, Ted, Scambos.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Excelent Services
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Grow your market
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Launch time
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
            </div>
          </div>
        </div>
      </section> -->
      <!-- <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    Want to work with us?
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <div class="relative w-full mb-3 mt-8">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="full-name">
                      Full Name
                    </label>
                    <input type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Full Name" />
                  </div>

                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
                      Email
                    </label>
                    <input type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email" />
                  </div>

                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="message">
                      Message
                    </label>
                    <textarea rows="4" cols="80"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Type a message..." />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button">
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import InfoComponent from "@/components/Cards/InfoTable.vue";

import video from "@/assets/img/videobackground.mp4";


//import team1 from "@/assets/img/team-1-800x800.jpg";
//import team2 from "@/assets/img/team-2-800x800.jpg";
//import team3 from "@/assets/img/team-3-800x800.jpg";
//import team4 from "@/assets/img/team-4-470x470.png";

export default {
  data() {
    return {
      video,
      HKSTP: 'https://upload.wikimedia.org/wikipedia/commons/2/29/HKSTP.png',
      innoHK: 'https://www.innohk.gov.hk/media/23dghxox/innohk-og-image.png',
      HKU: 'https://www.hku.hk/f/page/7561/150p169/1d@750.jpg',
      HKQAI: 'https://www.innohk.gov.hk/media/gyzljlq5/a38_hkqai_logo_v.jpg',
      CUHK: 'https://www.thestandard.com.hk/newsImage/20221018/50076316contentPhoto1.JPG'
    };
  },
  components: {
    Navbar,
    FooterComponent,
    InfoComponent,
  },
};
</script>